
import { computed, ref, nextTick } from 'vue';
import { InvoiceDocumentProduct } from '@/models/Accounting';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { generalStore } from '@/store';
import { Currency } from '@/models/Interfaces';

export default {
    name: 'EditInvoiceProductLine',
    props: {
        itemToEdit: InvoiceDocumentProduct,
        isCreditInvoice: Boolean,
        isPerfomaInvoice: Boolean
    },
    async setup(props: any, { emit }: any) {
        const { t } = useI18n();
        const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
        const nisCurrencyId = currencies.value.find(c => c.name == 'NIS')?.id;
        const item = ref<InvoiceDocumentProduct>(new InvoiceDocumentProduct());
        if (props.itemToEdit !== null) {
            item.value = JSON.parse(JSON.stringify(props.itemToEdit));
        }

        if (!item.value.quantity) item.value.quantity = '1';
        if (!item.value.currencyId && nisCurrencyId) item.value.currencyId = nisCurrencyId;

        const close = () => {
            emit('close');
        };

        const isNew = computed(() => {
            if (!item.value.id) {
                return true;
            }
            return false;
        });

        const getTotal = computed(() => {
            const result = 0;
            if (!item.value.quantity || item.value.quantity == '0') {
                return result;
            }
            if (!item.value.pricePerUnit || item.value.pricePerUnit == '0') {
                return result;
            }
            return Number(item.value.quantity) * Number(item.value.pricePerUnit) - Number(item.value.discount);
        });

        const addProductLine = async () => {
            item.value.total = Number(item.value.quantity) * Number(item.value.pricePerUnit) - Number(item.value.discount);
            if (!item.value.id) {
                item.value.id;
            }
            emit('addProductLine', item.value);
        };

        const cantAddLine = computed(() => {
            if (!item.value.quantity || item.value.quantity == '0') {
                return true;
            }
            if (!item.value.pricePerUnit || item.value.pricePerUnit == '0') {
                return true;
            }
            if (!item.value.name) {
                return true;
            }
            return false;
        });

        function filterKeyPrice(event: any) {
            if (event.key.trim() === '' || isNaN(event.key)) {
                event.preventDefault();
            }
            item.value.pricePerUnit = item.value.pricePerUnit.replace(/^0+/, '');
        }
        async function checkChangePrice(allowNegative: boolean) {
            await nextTick();
            let pricePerUnit = item.value.pricePerUnit.toString().replace(/[^0-9-]/g, '');

            if (allowNegative && pricePerUnit.startsWith('-')) {
                pricePerUnit = '-' + pricePerUnit.replace(/[^0-9]/g, '');
            } else {
                pricePerUnit = pricePerUnit.replace(/[^0-9]/g, '');
            }

            if (pricePerUnit.length === 1) {
                pricePerUnit = pricePerUnit.replace(/^0+/, '');
            }

            item.value.pricePerUnit = parseInt(pricePerUnit, 10).toString() || '';
        }

        async function checkChangeDiscount(allowNegative: boolean) {
            await nextTick();
            let discount = item.value.discount.toString().replace(/[^0-9-]/g, '');

            if (allowNegative && discount.startsWith('-')) {
                discount = '-' + discount.replace(/[^0-9]/g, '');
            } else {
                discount = discount.replace(/[^0-9]/g, '');
            }

            if (discount.length === 1) {
                discount = discount.replace(/^0+/, '');
            }

            item.value.discount = parseInt(discount, 10) || 0;
        }

        function filterKeyQty(event: any) {
            if (event.key.trim() === '' || isNaN(event.key)) {
                event.preventDefault();
            }
            item.value.quantity = item.value.quantity.replace(/^0+/, '');
        }
        async function checkChangeQty() {
            await nextTick();
            item.value.quantity = item.value.quantity.replace(/[\D]+/g, '');
            if (item.value.quantity && item.value.quantity.length == 1) {
                item.value.quantity = item.value.quantity.replace(/^0+/, '');
            }
        }

        async function deleteRow() {
            if (!item.value.id) {
                return;
            }
            const swalResult = await swal.fire({
                title: t('alert.areYouSure'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesContinue'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalResult.isConfirmed) return;
            emit('deleteProductLine', item.value.id);
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false || cantAddLine.value) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                addProductLine();
            }
        };

        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        return {
            item,
            getTotal,
            close,
            addProductLine,
            cantAddLine,
            checkChangePrice,
            checkChangeDiscount,
            filterKeyPrice,
            checkChangeQty,
            filterKeyQty,
            deleteRow,
            isNew,
            submitForm,
            getCurrencySymbol
        };
    },
    methods: {}
};
