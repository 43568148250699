
import { defineComponent, ref, reactive, computed, PropType, toRef } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import { BusinessInvoiceCustomer } from '@/models/Accounting';
import _ from 'lodash';
import Q from 'q';
import { IdText } from '@/models/Interfaces';
import { generalStore } from '@/store';
import Multiselect from '@vueform/multiselect';
import { BusinessInvoiceCustomersFilter } from '@/models/BusinessClients';
import { DateRange } from '@/models/Interfaces';
import useProduct from '@/modules/useProduct';
import Calendar from 'primevue/calendar';
import useOrder from '@/modules/useOrder';
import InputNumber from 'primevue/inputnumber';
import moment from 'moment';
import { InvoiceType } from '@/models/Enums';

export default defineComponent({
    components: {
        OverlayModal,
        Multiselect,
        Calendar,
        InputNumber
    },
    props: {
        clientId: {
            type: Number,
            required: true
        },
        customers: {
            type: [] as PropType<BusinessInvoiceCustomer[]>,
            default: []
        },
        totalAmount: {
            type: Number,
            default: 0
        },
        localCurrencySymbol: {
            type: String,
            default: ''
        },
        amountCoverByInvoces: {
            type: Number,
            default: 0
        },
        type: {
            type: Object as PropType<InvoiceType>,
            required: true
        }
    },
    name: 'BusinessInvoiceCustomersModal',
    async setup(props, { emit }: any) {
        const invoiceType = InvoiceType;
        const customerItems = toRef(props, 'customers');
        const amountCoverByInvocesValue = toRef(props, 'amountCoverByInvoces');
        const calendar = ref();
        const maxWidth = window.innerWidth;
        const selected = ref<string[]>([]);
        const selectedAmountCoverByInvoces = computed(x => {
            return customerItems.value
                .filter(x => selected.value.includes(x.id))
                .reduce((accumulator, object) => {
                    return accumulator + object.coverByInvoicesInLocal;
                }, 0);
        });
        const filter = ref<BusinessInvoiceCustomersFilter>(new BusinessInvoiceCustomersFilter(props.clientId));
        const acceptedFilter = ref<BusinessInvoiceCustomersFilter>(new BusinessInvoiceCustomersFilter(props.clientId));
        const customersToView = computed(() => {
            let items: BusinessInvoiceCustomer[] = JSON.parse(JSON.stringify(customerItems.value));
            if (acceptedFilter.value.orderNumber) {
                items = items.filter(x => x.orderNumber == acceptedFilter.value.orderNumber);
            }
            if (acceptedFilter.value.productId) {
                items = items.filter(x => x.productId == acceptedFilter.value.productId);
            }
            if (acceptedFilter.value.couponId) {
                items = items.filter(x => x.couponId == acceptedFilter.value.couponId);
            }
            if (acceptedFilter.value.arrivalDateStart) {
                const startDate = moment(acceptedFilter.value.arrivalDateStart, 'YYYY/MM/DD');
                items = items.filter(x => moment(x.arrivalDate, 'DD/MM/YYYY') >= startDate);
            }
            if (acceptedFilter.value.arrivalDateEnd) {
                const startDate = moment(acceptedFilter.value.arrivalDateEnd, 'YYYY/MM/DD');
                items = items.filter(x => moment(x.arrivalDate, 'DD/MM/YYYY') <= startDate);
            }
            if (acceptedFilter.value.createDateStart) {
                const startDate = moment(acceptedFilter.value.createDateStart, 'YYYY/MM/DD');
                items = items.filter(x => moment(x.createdDate, 'DD/MM/YYYY') >= startDate);
            }
            if (acceptedFilter.value.createDateEnd) {
                const startDate = moment(acceptedFilter.value.createDateStart, 'YYYY/MM/DD');
                items = items.filter(x => moment(x.createdDate, 'DD/MM/YYYY') <= startDate);
            }
            return items;
        });
        const checkAll = ref(false);
        const isOpen = ref(false);
        const providerProducts = ref<IdText[]>([]);
        const providerCoupons = ref<IdText[]>([]);
        const { dateRangeValidate } = useProduct();
        const dateRange = reactive(new DateRange());
        const dateRange2 = reactive(new DateRange());
        const selectedCount = computed(() => selected.value.length);
        const totalAmountValue = toRef(props, 'totalAmount');
        const { convertDate } = useOrder();
        const localCurrencySymbolValue = toRef(props, 'localCurrencySymbol');
        if (filter.value?.createDateStart) {
            dateRange.fromDate = new Date(filter.value?.createDateStart);
        }
        if (filter.value?.createDateEnd) {
            dateRange.toDate = new Date(filter.value?.createDateEnd);
        }

        const back = () => {
            isOpen.value = false;
        };
        async function save() {
            emit('addCustomers', selected.value);
            back();
        }

        async function search() {
            swal.showLoading();
            const filterObj: BusinessInvoiceCustomersFilter = JSON.parse(JSON.stringify(filter.value));
            if (filterObj.productId == '') {
                filterObj.productId = null;
            }
            if (filterObj.couponId == '') {
                filterObj.couponId = null;
            }
            if (dateRange.fromDate) {
                filterObj.arrivalDateStart = convertDate(dateRange.fromDate);
            }
            if (dateRange.toDate) {
                filterObj.arrivalDateEnd = convertDate(dateRange.toDate);
            }
            if (dateRange2.fromDate) {
                filterObj.createDateStart = convertDate(dateRange2.fromDate);
            }
            if (dateRange2.toDate) {
                filterObj.createDateEnd = convertDate(dateRange2.toDate);
            }
            acceptedFilter.value = filterObj;
            swal.close();
        }

        async function open() {
            swal.showLoading();
            const providerProductsPromise = generalStore.dispatch('getMyProducts');
            const providerCouponsPromise = generalStore.dispatch('getProviderCoupons');
            await Q.delay(400);
            providerProducts.value = await providerProductsPromise;
            providerCoupons.value = await providerCouponsPromise;
            isOpen.value = true;
            swal.close();
        }

        function selectAll() {
            checkAll.value = !checkAll.value;
            if (checkAll.value) {
                selected.value = customerItems.value.map(x => x.id);
            } else {
                selected.value = [];
            }
        }

        function isChecked(id: string) {
            return selected.value.includes(id);
        }

        function onSelect(id: string) {
            const index = _.findIndex(selected.value, x => x == id);
            if (index > -1) {
                checkAll.value = false;
                selected.value.splice(index, 1);
            } else {
                selected.value.push(id);
            }
        }

        const dateChanged = () => {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
        };

        function clearFilter() {
            filter.value = new BusinessInvoiceCustomersFilter(props.clientId);
            dateRange.fromDate = null;
            dateRange.toDate = null;
            dateRange2.fromDate = null;
            dateRange2.toDate = null;
        }

        function removeSelectedItem(itemId: string) {
            const index = selected.value.findIndex(x => x == itemId);
            if (index >= 0) {
                selected.value.splice(index, 1);
            }
        }

        return {
            back,
            customersToView,
            maxWidth,
            selectAll,
            isChecked,
            onSelect,
            isOpen,
            open,
            checkAll,
            save,
            selected,
            providerProducts,
            providerCoupons,
            filter,
            dateRange,
            dateRange2,
            dateChanged,
            calendar,
            selectedCount,
            selectedAmountCoverByInvoces,
            clearFilter,
            search,
            totalAmountValue,
            localCurrencySymbolValue,
            amountCoverByInvocesValue,
            removeSelectedItem,
            invoiceType
        };
    }
});
