import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Invoice = _resolveComponent("Invoice")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Invoice, {
      isNew: true,
      clientId: _ctx.clientId,
      type: _ctx.invoiceType.Debit
    }, null, 8, ["clientId", "type"])
  ]))
}